import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { createRef, useContext, useState } from "react";
import { login } from "../../services/api/authApiService";
import axios from "axios";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { AuthUserContext } from "../../navigation/AuthUserProvider";
import Header from "../../components/Header";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import background from "../../assets/bg/login_bg.jpg";

const MySwal = withReactContent(Swal);

const Login = () => {
  const { setProfile } = useContext(AuthUserContext);
  // const [email,setEmail] = useState('patosmack+gym10@gmail.com');
  // const [password,setPassword] = useState('asd123*');
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  function emailIsValid() {
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      return true;
    }
    return false;
  }

  const onSubmit = () => {
    if (!loading) {
      if (!emailIsValid(email)) {
        //alert('A Valid Email address is required')

        MySwal.fire({
          text: "A Valid Email address is required",
          icon: "error",
          confirmButtonColor: "#eba14a",
          confirmButtonText: "Back",
        });
        return;
      }
      if (password.length <= 0) {
        MySwal.fire({
          text: "The Password is required",
          icon: "error",
          confirmButtonColor: "#eba14a",
          confirmButtonText: "Back",
        });
        return;
      }
      setLoading(true);
      login(email, password)
        .then((res) => {
          localStorage.setItem("token", res.tokens.access_token);
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${res.tokens.access_token}`;
          setProfile(res);
        })
        .catch((e) => {
          console.log(e);
          MySwal.fire({
            text: "The email or password is not correct",
            icon: "error",
            confirmButtonColor: "#eba14a",
            confirmButtonText: "Back",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div
      className={"main_bg"}
      style={{ backgroundImage: `url(${background})` }}
    >
      <Header />
      <Container className="pt-5">
        <Row>
          <Col lg={4} md={6} sm={7} className="mx-auto"></Col>
        </Row>
        <Row>
          <Col lg={4} md={6} sm={7} className="mx-auto">
            <div className="formLogin">
              <h4>log in to your fittly dashboard</h4>

              <Form.Group className="mb-3 grouped-input">
                <Form.Label>email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Type"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      if (emailIsValid(event.target.value)) {
                        document.getElementById("password").focus();
                      } else {
                        MySwal.fire({
                          text: "A Valid Email address is required",
                          icon: "error",
                          confirmButtonColor: "#eba14a",
                          confirmButtonText: "Back",
                        });
                      }
                    }
                  }}
                />
                {/*<Form.Text className="text-muted">*/}
                {/*    We'll never share your email with anyone else.*/}
                {/*</Form.Text>*/}
              </Form.Group>
              <Form.Group className="mb-3 grouped-input">
                <Form.Label>password</Form.Label>
                <Form.Control
                  id={"password"}
                  type="password"
                  placeholder="Type"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      onSubmit();
                    }
                  }}
                />
              </Form.Group>
              <Row>
                <Col
                  className="w-100 d-flex justify-content-center pt-4 pb-4"
                  style={{ marginTop: 20, marginRight: 30, marginLeft: 30 }}
                >
                  {!loading ? (
                    <Button
                      type="submit"
                      className="btn btn-blue mx-auto w-100"
                      onClick={onSubmit}
                    >
                      Log In
                    </Button>
                  ) : (
                    <Loader
                      type="ThreeDots"
                      color="#31aeb8"
                      height={30}
                      width={30}
                    />
                  )}
                </Col>
                {/*<Form.Text className="text-muted text-center">*/}
                {/*    Don’t have an account yet? Register in our app*/}
                {/*</Form.Text>*/}
                {/*<a href={'#'} className="text-muted text-center">*/}
                {/*    Register in our app*/}
                {/*</a>*/}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
