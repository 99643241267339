import { useEffect, useState } from "react";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import Billing from "../../screens/Billing";
import UpgradePlan from "../../screens/UpgradePlan";
import YourPlan from "../../screens/YourPlan";
import Header from "../Header";
import background from "../../assets/bg/plan_bg.jpg";

const urlParams = new URLSearchParams(window.location.search);
const targetKey = urlParams.get("target") || "plan";

const NavOptions = () => {
  // const [key, setKey] = useState("plan");
  // useEffect(() => {
  //   setKey(targetKey);
  // }, [targetKey]);

  return (
    <div
      className={"main_bg"}
      style={{ backgroundImage: `url(${background})` }}
    >
      <Tab.Container id="main-nav" defaultActiveKey={targetKey}>
        <Header />
        <Container className="pt-5">
          <Row>
            <Col md={12}>
              <Tab.Content>
                <Tab.Pane eventKey="plan">
                  <YourPlan />
                </Tab.Pane>
                <Tab.Pane eventKey="upgrade">
                  <UpgradePlan />
                </Tab.Pane>
                <Tab.Pane eventKey="billing">
                  <Billing />
                </Tab.Pane>
              </Tab.Content>
              {/*<Tabs*/}
              {/*    id="controlled-tab-example"*/}
              {/*    activeKey={key}*/}
              {/*    onSelect={(k) => setKey(k)}*/}
              {/*    className="mb-3 navOptions"*/}
              {/*>*/}
              {/*  <Tab eventKey="plan" title="Plan">*/}
              {/*    <YourPlan />*/}
              {/*  </Tab>*/}
              {/*  <Tab eventKey="upgrade" title="Manage Suscription">*/}
              {/*    <UpgradePlan />*/}
              {/*  </Tab>*/}
              {/*  <Tab eventKey="billing" title="Billing Information">*/}
              {/*    <Billing />*/}
              {/*  </Tab>*/}
              {/*</Tabs>*/}
            </Col>
          </Row>
        </Container>
      </Tab.Container>
    </div>
  );
};

export default NavOptions;
