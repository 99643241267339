import { Row, Col, Button } from "react-bootstrap";
import stripe from "../../assets/stripe.png";
import {
  getInvoices,
  getPaymentPortalURL,
} from "../../services/api/commonApiService";
import { useContext, useEffect, useState } from "react";
import { AuthUserContext } from "../../navigation/AuthUserProvider";

const PaymentInfo = () => {
  const { profile } = useContext(AuthUserContext);

  const [customerAddress, setCustomerAddress] = useState(null);
  const [customerEmail, setCustomerEmail] = useState(null);
  const [customerName, setCustomerName] = useState(null);

  useEffect(() => {
    console.log(profile);
  }, [profile]);

  useEffect(() => {
    getInvoices(1)
      .then((res) => {
        if (res.length > 0) {
          let invoice = res[0];
          setCustomerAddress(invoice.customer_address);
          setCustomerEmail(invoice.customer_email);
          setCustomerName(invoice.customer_name);
        }
      })
      .catch(() => {});
  }, []);

  return (
    <div className="boxDueDate billingInfo">
      <Row>
        <Col className="d-flex justify-content-between">
          <h4 className="pt-2 pb-3">Billing information</h4>
        </Col>
      </Row>

      <div className="titularInfo text-center text-sm-start pt-4 pt-sm-0 pb-4 pb-sm-0">
        {customerName ? <h4>{customerName}</h4> : null}
        {customerEmail ? <p>{customerEmail}</p> : null}
        {customerAddress ? (
          <div className="address pt-3">
            {customerAddress.line1 ? (
              <p>Address Line 1: {customerAddress.line1}</p>
            ) : null}
            {customerAddress.line2 ? (
              <p>Address Line 2: {customerAddress.line2}</p>
            ) : null}
            {customerAddress.city ? <p>City: {customerAddress.city}</p> : null}
            {customerAddress.state ? (
              <p>State: {customerAddress.state}</p>
            ) : null}
            {customerAddress.postal_code ? (
              <p>ZipCode: {customerAddress.postal_code}</p>
            ) : null}
          </div>
        ) : null}

        <Button
          className="btn btn-stripe little w-100 mt-4"
          onClick={() => {
            getPaymentPortalURL("billing")
              .then((url) => {
                window.location.href = url;
                return null;
              })
              .catch(() => {});
          }}
        >
          Update payment with
          <img src={stripe} alt="Stripe" />
        </Button>
      </div>
    </div>
  );
};

export default PaymentInfo;
