
import axios from 'axios'

export const getPlans = () => {
    return new Promise((resolve, reject) => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_API_BASE_URL}/plan`,
        })
            .then(function (response) {
                resolve(response.data.plans);
            })
            .catch(function (response) {
                reject(response)
            });


    });
};


export const getFeatures = () => {
    return new Promise((resolve, reject) => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_API_BASE_URL}/feature`,
        })
            .then(function (response) {
                resolve(response.data.features);
            })
            .catch(function (response) {
                reject(response)
            });


    });
};


export const getJobSlots = () => {
    return new Promise((resolve, reject) => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_API_BASE_URL}/plan/jobs`,
        })
            .then(function (response) {
                resolve(response.data.jobs);
            })
            .catch(function (response) {
                reject(response)
            });


    });
};

export const generatePaymentUrl = (plan_id) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_API_BASE_URL}/plan/subscribe`,
            data: {
                plan_id
            }
        })
            .then(function (response) {
                resolve(response.data.url);
            })
            .catch(function (response) {
                reject(response)
            });


    });
};


export const getProrate = (plan_id) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_API_BASE_URL}/plan/prorate`,
            data: {
                plan_id
            }
        })
            .then(function (response) {
                resolve(response.data.invoice);
            })
            .catch(function (response) {
                reject(response)
            });
    });
};



export const getInvoices = (limit = 12) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_API_BASE_URL}/plan/invoices/${limit}`,
        })
            .then(function (response) {
                resolve(response.data.invoices);
            })
            .catch(function (response) {
                reject(response)
            });


    });
};



export const getPaymentPortalURL = (target = 'home') => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_API_BASE_URL}/plan/portal`,
            data: {
                return_url: `${process.env.REACT_APP_REDIRECT_BASE_URL}/?target=${target}`
            }
        })
            .then(function (response) {
                resolve(response.data.url);
            })
            .catch(function (response) {
                reject(response)
            });
    });
};



export const getPlanUpgradeUrl = (plan_id, target = 'home') => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_API_BASE_URL}/plan/upgrade`,
            data: {
                plan_id: plan_id,
                return_url: `${process.env.REACT_APP_REDIRECT_BASE_URL}/?target=${target}`
            }
        })
            .then(function (response) {
                resolve(response.data.url);
            })
            .catch(function (response) {
                reject(response)
            });
    });
};

