import { Row, Col } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { AuthUserContext } from "../../navigation/AuthUserProvider";
import moment from "moment";
import { getProrate } from "../../services/api/commonApiService";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import iconCalendar from "../../assets/calendar.png";

const DueDate = () => {
  const { profile } = useContext(AuthUserContext);
  const [paymentInfo, setPaymentInfo] = useState(null);

  useEffect(() => {
    if (profile.user.user_plan) {
      getProrate(profile.user.user_plan.plan_id)
        .then((res) => {
          console.log("////////////////////////////");
          console.log(res);
          console.log("////////////////////////////");

          let expiration_date = profile.user.user_plan.expiration_date;
          let current_plan = profile.user.user_plan.plan.price_id;

          console.log(profile.user.user_plan);

          let remotePlanID = null;
          if (
            res &&
            res.lines &&
            res.lines.data.length > 0 &&
            res.lines.data[0] &&
            res.lines.data[0].plan
          ) {
            let remotePlanID = res.lines.data[0].plan.id;
            console.log(`Plan ID: ${remotePlanID}`);
          }

          // let remotePlanID = null;
          // if(res && res.lines && res.lines.data.length > 0 && res.lines.data[0] && res.lines.data[0].plan){
          //   let remotePlanID = res.lines.data[0].plan.id
          //   console.log(`Plan ID: ${remotePlanID}`)
          // }

          //
          //price_id
          //code
          setPaymentInfo(res);

          // paymentInfo?.amount_remaining
          // paymentInfo?.next_payment_attempt * 1000
        })
        .catch(() => {});
    }
  }, [profile]);

  return (
    <div className="boxDueDate">
      <Row>
        <Col className="d-flex justify-content-between">
          <h4 className="pt-2">Upcoming Payments</h4>
          <img src={iconCalendar} />
        </Col>
      </Row>

      <div className="date d-sm-flex justify-content-around align-items-center d-block text-sm-start text-center">
        <div>
          <h5>
            {/*{(profile && profile.user && profile.user.user_plan && profile.user.user_plan.plan  && profile.user.user_plan.plan.code === 'fittly_profile') ? 'Free' : null }*/}
            {/*{(profile && profile.user && profile.user.user_plan && profile.user.user_plan.plan  && profile.user.user_plan.plan.code !== 'fittly_profile') ? (paymentInfo ? (<span>$</span>) : null)  : null }*/}
            {/*{(profile && profile.user && profile.user.user_plan && profile.user.user_plan.plan  && profile.user.user_plan.plan.code !== 'fittly_profile') ? (paymentInfo ? `${paymentInfo?.amount_remaining / 100}` : (*/}
            {/*    <Loader*/}
            {/*        type="ThreeDots"*/}
            {/*        color="#0D6E76"*/}
            {/*        height={30}*/}
            {/*        width={30}*/}
            {/*    />*/}
            {/*)) : null }*/}

            {profile &&
            profile.user &&
            profile.user.user_plan &&
            profile.user.user_plan.plan &&
            profile.user.user_plan.plan.code === "fittly_profile"
              ? "Free"
              : null}
            {profile &&
            profile.user &&
            profile.user.user_plan &&
            profile.user.user_plan.plan &&
            profile.user.user_plan.plan.code !== "fittly_profile" ? (
              paymentInfo ? (
                <span>$</span>
              ) : null
            ) : null}
            {profile &&
            profile.user &&
            profile.user.user_plan &&
            profile.user.user_plan.plan &&
            profile.user.user_plan.plan.code !== "fittly_profile" ? (
              paymentInfo ? (
                `${paymentInfo?.amount_remaining / 100}`
              ) : (
                <Loader
                  type="ThreeDots"
                  color="#0D6E76"
                  height={30}
                  width={30}
                />
              )
            ) : null}
          </h5>
        </div>
        <div>
          {/*<p className="dueDateTitle">$ in favor</p>*/}
          {/*{(profile && profile.user && profile.user.user_plan && profile.user.user_plan.expiration_date ) ? (*/}
          {/*    <p className="dueDate">{ moment(new Date(profile.user.user_plan.expiration_date)).format("MMMM Do YYYY")}</p>*/}
          {/*) : null}*/}

          {profile &&
          profile.user &&
          profile.user.user_plan &&
          profile.user.user_plan.plan &&
          profile.user.user_plan.plan.code === "fittly_profile" ? (
            ""
          ) : paymentInfo ? (
            <p className="dueDateTitle">
              due in{" "}
              {moment(new Date(paymentInfo?.next_payment_attempt * 1000)).diff(
                moment(new Date()),
                "days"
              )}{" "}
              days
            </p>
          ) : null}
          {profile &&
          profile.user &&
          profile.user.user_plan &&
          profile.user.user_plan.plan &&
          profile.user.user_plan.plan.code === "fittly_profile" ? (
            ""
          ) : paymentInfo ? (
            <p className="dueDate">
              {moment
                .unix(paymentInfo?.next_payment_attempt * 1000)
                .format("MMMM Do YYYY")}
            </p>
          ) : null}

          {/*<p className="dueDateTitle">{(profile && profile.user && profile.user.user_plan && profile.user.user_plan.expiration_date ) ? `due in ${moment(new Date(profile.user.user_plan.expiration_date)).diff(moment(new Date()), 'days')} days` : 'Never Expires' } </p>*/}
          {/*{(profile && profile.user && profile.user.user_plan && profile.user.user_plan.expiration_date ) ? (*/}
          {/*    <p className="dueDate">{ moment(new Date(profile.user.user_plan.expiration_date)).format("MMMM Do YYYY")}</p>*/}
          {/*) : null}*/}
        </div>
      </div>
      {profile &&
      profile.user &&
      profile.user.user_plan &&
      profile.user.user_plan.plan ? (
        <p className="billingDate">
          Plan: <b>{profile.user.user_plan.plan.name}</b>
        </p>
      ) : null}
    </div>
  );
};

export default DueDate;
