import { Container, Row, Col, Button, Nav } from "react-bootstrap";
import logo from "../../assets/logo_header.png";
import { useContext } from "react";
import { AuthUserContext } from "../../navigation/AuthUserProvider";

import activeCircle from "../../assets/half_circle_active.png";
function Header() {
  const { profile, setProfile } = useContext(AuthUserContext);
  return (
    <Container fluid className="header">
      <Container>
        <Row className="d-sm-flex justify-content-between d-block">
          <Col md={3} className="text-sm-start text-center pt-sm-0">
            <Nav.Link eventKey="plan" className={"tab"}>
              <img src={logo} alt="Fittly" className="mt-3" />
            </Nav.Link>
          </Col>
          {/*<Col className="d-flex justify-content-sm-start justify-content-center">*/}
          {/*  <img src={logo} alt="Fittly" />*/}
          {/*</Col>*/}
          {/*  <Col className="d-flex justify-content-end justify-content-center">*/}
          {profile && (
            <Col className="pull-end d-sm-flex d-block text-sm-start text-center">
              <Nav className="tab-navs">
                <Nav.Item>
                  <Nav.Link eventKey="plan" className={"tab"}>
                    <img src={activeCircle} />
                    <p>Your plan</p>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="upgrade" className={"tab"}>
                    <img src={activeCircle} />
                    <p>Manage Subscription</p>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="billing" className={"tab"}>
                    <img src={activeCircle} />
                    <p>Payments & Billing</p>
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              <Button
                className="btn btn-gray little"
                onClick={() => {
                  localStorage.removeItem("token");
                  setProfile(null);
                }}
              >
                Log Out
              </Button>
            </Col>
          )}
        </Row>
      </Container>
    </Container>
  );
}

export default Header;
