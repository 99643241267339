import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "../components/Header";
import Login from "../screens/Login";
import NavOptions from "../components/NavOptions";
import {useContext, useEffect, useState} from "react";
import {AuthUserContext} from "./AuthUserProvider";
import axios from "axios";
import {getProfile} from "../services/api/authApiService";

export default function Routes() {

    const { profile, setProfile } = useContext(AuthUserContext);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let token = localStorage.getItem('token');
        if(token){
            setLoading(true);
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            getProfile().then((res) => {
                setProfile(res)
            }).catch((e) => {
                localStorage.removeItem('token');
                setProfile(null)
            }).finally(() => {
                setLoading(false);
            })
        }else{
            setLoading(false)
        }
    }, []);

    if (loading) {
        return null;
    }

    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    {profile ? <NavOptions /> : <Login/> }
                </Route>
                {/*<Route exact path="/plan">*/}
                {/*    <NavOptions />*/}
                {/*</Route>*/}
            </Switch>
        </Router>
    );
}
