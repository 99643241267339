import { Row, Col } from "react-bootstrap";
// import editIcon from "../../assets/edit-icon.png";
import DueDate from "../../components/DueDate";
import ListInvoices from "../../components/ListInvoices";
import PaymentInfo from "../../components/PaymentInfo";

const Billing = () => {
  return (
    <Row className="pt-4">
      <Col md={12}>
        <Row>
          <Col md={10} xs={12} className="mx-auto">
            <Row>
              <Col md={6} xs={12} className="pb-4">
                <DueDate />
              </Col>
              <Col md={6} xs={12} className="pb-4">
                <PaymentInfo />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="">
                <ListInvoices />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Billing;
