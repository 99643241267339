import { Row, Col, Table } from "react-bootstrap";
import {useEffect, useState} from "react";
import {getInvoices} from "../../services/api/commonApiService";
import moment from "moment";

const ListInvoices = () => {

  const [invoices, setInvoices] = useState([]);

  useEffect(() => {

    getInvoices().then((res) => {
      setInvoices(res)
    }).catch(() => {

    })


  }, [])

  return (
    <Row className="invoicesTable">
      <Col md={12} className="mx-auto">
        <Row>
          <Col md={12}>
            <h4>Payment History</h4>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="listInvoices">
              <Table responsive>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th className="align-right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                {invoices.map((item) => {

                  let date = moment.unix(item.created).format("MMMM Do YYYY");
                  let total = 0;
                  if(item.total !== 0){
                    total = item.total / 100
                  }
                  let download = null;
                  if(item.invoice_pdf){
                    download = item.invoice_pdf;
                  }
                  return (
                      <tr className="invoice" key={`invoice_${item.id}`}>
                        <td>
                          <p className="text">{date}</p>
                        </td>
                        <td>
                          <div>
                            <p className="text bold">{total !== 0 ? `$${total}` : 'Free'}</p>
                            <a href={download} className={'download_pdf'} target="_blank">download</a>
                          </div>
                        </td>
                      </tr>
                  )
                })}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ListInvoices;
