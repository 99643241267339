import { Row, Col, ProgressBar, Button } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { AuthUserContext } from "../../navigation/AuthUserProvider";
import Loader from "react-loader-spinner";
import { getJobSlots } from "../../services/api/commonApiService";

const YourPlan = () => {
  const { profile } = useContext(AuthUserContext);
  const [jobSlots, setJobSlots] = useState(null);

  useEffect(() => {
    getJobSlots()
      .then((res) => {
        setJobSlots(res);
      })
      .catch(() => {});
  }, [profile]);

  if (!jobSlots) {
    return (
      <Row>
        <Col md={12} className="text-center">
          <Loader type="ThreeDots" color="#0D6E76" height={30} width={30} />
        </Col>
      </Row>
    );
  }

  let dynamicWidth = "calc(" + (jobSlots.active_jobs * 100) / jobSlots.jobs_per_month + "% - 50px )";

  return (
    <Row>
      <Col md={11} lg={10} className="mx-auto  pt-sm-5 pt-0">
        <Row className="headerPlan  pt-sm-5 pt-0">
          <Col md={12}>
            <h3 className="text-center text-sm-start">
              You’re currently <span>using {jobSlots.active_jobs} job posts</span> this month
            </h3>
            <h6 className="text-center text-sm-start">
              out of {jobSlots.jobs_per_month} total available in your current plan
            </h6>
          </Col>
        </Row>
        <Row className="planInfo pb-5">
          <Col md={12}>
            <div className="containerInfo">
              <div className="w-100 d-flex justify-content-between pb-3 flex-md-row flex-column">
                <p className="label pt-1 mb-0 text-md-start text-center mb-md-0 mb-4">Active jobs</p>
                <Button
                  className="btn btn-blue little"
                  style={{ fontSize: 16 }}
                  onClick={() => {
                    window.location.href = `${process.env.REACT_APP_REDIRECT_BASE_URL}?target=upgrade`;
                    return null;
                  }}
                >
                  Get more posts
                </Button>
              </div>

              <div className="d-block">
                <Row>
                  <Col md={11} xs={12} className="mx-auto d-sm-flex justify-content-center d-block">
                    <p className="label d-none d-sm-block noSelectable " style={{ paddingRight: 20, color: "white" }}>
                      {0}
                    </p>
                    <div style={{ minWidth: "85%" }}>
                      <p className="totalJobsNow" style={{ marginLeft: dynamicWidth }}>
                        {jobSlots.active_jobs}
                      </p>
                    </div>
                    <div
                      className="w-100 d-sm-flex d-none justify-content-between noSelectable "
                      style={{ paddingLeft: 20 }}
                    >
                      <p className="label" style={{ color: "white" }}>
                        {jobSlots.jobs_per_month}/Month
                      </p>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={11} xs={12} className="mx-auto d-sm-flex justify-content-center d-block">
                    <p className="label d-none d-sm-block" style={{ paddingRight: 20 }}>
                      {0}
                    </p>
                    <ProgressBar
                      now={(jobSlots.active_jobs * 100) / jobSlots.jobs_per_month}
                      style={{ minWidth: "85%" }}
                    />
                    <div className="w-100 d-flex dm-sm-none justify-content-between" style={{ paddingLeft: 20 }}>
                      <p className="label"> {jobSlots.jobs_per_month}/Month</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default YourPlan;
