import React from 'react';
import "./scss/app.scss";
import {AuthUserProvider} from "./navigation/AuthUserProvider";
import Routes from "./navigation/Routes";

function App() {
    return (
        <AuthUserProvider>
            <Routes/>
        </AuthUserProvider>
    );
}
export default App;
