import React, { useState, createContext } from 'react';

export const AuthUserContext = createContext({});

export const AuthUserProvider = ({ children }) => {
  const [profile, setProfile] = useState(null);

  return (
    <AuthUserContext.Provider value={{ profile, setProfile}}>
      {children}
    </AuthUserContext.Provider>
  );
};
