import { Row, Col, Button } from "react-bootstrap";
import { CheckmarkOutline } from "react-ionicons";
import { findByValue } from "../../utils/helpers";
import { generatePaymentUrl, getPlanUpgradeUrl } from "../../services/api/commonApiService";
import { useContext } from "react";
import { AuthUserContext } from "../../navigation/AuthUserProvider";

const Plan = ({ title, subtitle, price, totalOptions, activeOptions, current, popular, free, planId }) => {
  const getLink = (plan) => {
    getPlanUpgradeUrl(plan, "upgrade")
      .then((url) => {
        window.location.href = url;
        return null;
      })
      .catch(() => {
        alert("There was a problem loading payment information, please try again");
      });
  };

  return (
    <>
      <Row>
        <Col md={12} className={`planBox ${current ? "current pb-md-4 pb-3 pt-5" : "pt-0 pb-2"}   `}>
          <div className={`priceContain pt-5 ${current ? "current" : ""}  `}>
            <div>
              <h4 className={`${current && "current"} `}>{title}</h4>
              {subtitle && (
                <div className="subtitle">
                  <h5>{subtitle}</h5>
                </div>
              )}
              <div className="headPrice">
                <p className="price">
                  <sub>$</sub>
                  {price}
                </p>
                <p className="regularity">/month</p>
              </div>

              <ul className="list-unstyled items text-left">
                {totalOptions.map((item) => {
                  let res = findByValue(item.plans, "plan_id", planId);
                  if (!res) {
                    return (
                      <li key={`plan_check_${item.id}`}>
                        <p className="disabledItem">
                          <span className="check">-</span> {item.text}
                        </p>
                      </li>
                    );
                  }
                  if (item.type === "INT") {
                    return (
                      <li key={`plan_check_${item.id}`} className="d-flex justify-content-start">
                        <CheckmarkOutline color={"green"} height="15px" width="15px" />
                        <p className="fw-bold pt-1">
                          <span>{res.value}</span> {item.text}
                        </p>
                      </li>
                    );
                  } else {
                    return (
                      <li key={`plan_check_${item.id}`} className="d-flex justify-content-start">
                        <CheckmarkOutline color={"green"} height="15px" width="15px" />
                        <p className="pt-1">{item.text}</p>
                      </li>
                    );
                  }
                })}
              </ul>

              {current ? (
                <div className="btn btn-white little w-100">Current plan</div>
              ) : (
                <Button
                  className={`btn ${free ? "btn-outline-blue" : "btn-blue"} little w-100`}
                  onClick={() => getLink(planId)}
                >
                  Choose this plan
                </Button>
              )}
            </div>
          </div>
        </Col>
        <div className={`w-100 text-center containRemove ${current ? "pt-2" : "pt-0"}   `}>
          {current && (
            <button className="removeButton mx-auto" onClick={() => getLink(1)}>
              remove this plan
            </button>
          )}
        </div>
      </Row>
    </>
  );
};

export default Plan;
