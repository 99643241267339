import { Row, Col } from "react-bootstrap";
import Plan from "../../components/Plan";
import { useContext, useEffect, useState } from "react";
import { getFeatures, getPlans } from "../../services/api/commonApiService";
import { AuthUserContext } from "../../navigation/AuthUserProvider";

const UpgradePlan = () => {
  const { profile } = useContext(AuthUserContext);
  const [plans, setPlans] = useState([]);
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    getPlans().then((res) => {
      setPlans(res);
    });
    getFeatures().then((res) => {
      setFeatures(res);
    });
  }, []);

  return (
    <Row>
      {/* <Col md={3} xs={12} className="d-flex flex-start">
        <div className="p-4 pt-5 pb-0">
          <h2 className="textPrices pt-sm-5 pt-0 text-sm-start text-center">
            Fittly Subscriptions for gyms and studios
          </h2>
          <div className="specsLeft d-sm-block d-none">
            <ul className="list-unstyled">
              <li>
                <p className="descripPrices">Features</p>
              </li>
              {features && features.map((item) => (
                <li key={`features_${item.id}`}>
                  <p>{item.text}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Col> */}
      <Col md={12}>
        <Row className={"d-flex justify-content-center align-items-center"}>
          {plans &&
            plans.map((itm) => {
              if (itm.price > 0) {
                return (
                  <Col md={3} xs={12} key={`plan_container_${itm.code}`}>
                    <Plan
                      key={`plan_${itm.id}`}
                      subtitle={itm.description}
                      planId={itm.id}
                      title={itm.name}
                      price={itm.price}
                      totalOptions={features}
                      activeOptions={[]}
                      free={itm.price <= 0}
                      current={
                        profile && profile.user && profile.user.user_plan
                          ? profile.user.user_plan.plan_id === itm.id
                          : false
                      }
                      popular={itm.price >= 0 && itm.featured === true}
                      // current={itm.featured}
                    />
                  </Col>
                );
              }
            })}
        </Row>
      </Col>
    </Row>
  );
};

export default UpgradePlan;
