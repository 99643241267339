import axios from 'axios'

export const login = (email,password) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_API_BASE_URL}/user/login`,
            data: {
                username: email,
                password: password,
            },
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                reject(response)
            });


    });
};

export const resetPassword = (email) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_API_BASE_URL}/user/reset`,
            data: {
                email: email,
            },
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                reject(response)
            });
    });
};

export const refreshToken = (email,password) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_API_BASE_URL}/refresh`,
            data: {
                username: email,
                password: password,
            },
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                reject(response)
            });


    });
};

export const getProfile = () => {
    return new Promise((resolve, reject) => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_API_BASE_URL}/user/profile/me`
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                reject(response)
            });


    });
};