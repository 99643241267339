import _ from 'lodash'
export function chunk(inputArray, chunkSize){
    return inputArray.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index/chunkSize)
        if(!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []
        }
        resultArray[chunkIndex].push(item)
        return resultArray
    }, [])
}

export function findByValue(arrayOfObjects, key, value){
    try{
        return arrayOfObjects.find(obj => {
            if(obj[key] !== undefined){
                return obj[key] == value
            }
            return false
        })
    }catch (e) {

    }
    return false
}

export function extractDiffInObject(object, base) {
    return _.omitBy(object, function(v, k) {
        return objectsAreEqual(base[k], v);
    });
}

export function findObjectIndex(inputArray, object, dismissTimestams = true, dismissKeys=[]){
    return _.find(inputArray, function(o) {

        let tmp1 =  Object.assign({}, o);
        let tmp2 =  Object.assign({}, object);
        if(dismissTimestams){
            delete tmp1.created_at
            delete tmp1.updated_at
            delete tmp1.deleted_at
            delete tmp1.TrainerSpecialty

            delete tmp2.created_at
            delete tmp2.updated_at
            delete tmp2.deleted_at
            delete tmp2.TrainerSpecialty
        }
        for (let i = 0; i < dismissKeys.length; i++) {
            delete tmp1[dismissKeys[i]]
            delete tmp2[dismissKeys[i]]
        }
        return JSON.stringify(tmp1) === JSON.stringify(tmp2)
    });
}
export function objectExists(inputArray, object, dismissTimestams = true, dismissKeys=[]){
    return findObjectIndex(inputArray, object) !== undefined;
}
export function filterByObject(inputArray, object){
    return inputArray.filter((o) => JSON.stringify(o) !== JSON.stringify(object))
}

export function filterByValue(inputArray, key, value){
    return inputArray.filter((o) => {
        return o[key] !== value
    })
}
export function replaceObject(inputArray, object, newObject){
    return _.map(inputArray, function(o) {
        let itm = JSON.stringify(o) === JSON.stringify(object) ? newObject : o;
        return itm;
    });
}
export function objectsAreEqual(object1, object2, dismissTimestams = true, dismissKeys=[]){
    let tmp1 =  Object.assign({}, object1);
    let tmp2 =  Object.assign({}, object2);
    if(dismissTimestams){
        delete tmp1.created_at
        delete tmp1.updated_at
        delete tmp1.deleted_at
        delete tmp1.TrainerSpecialty

        delete tmp2.created_at
        delete tmp2.updated_at
        delete tmp2.deleted_at
        delete tmp2.TrainerSpecialty
    }

    for (let i = 0; i < dismissKeys.length; i++) {
        delete tmp1[dismissKeys[i]]
        delete tmp2[dismissKeys[i]]
    }
    return JSON.stringify(tmp1) === JSON.stringify(tmp2)
}


export function cleanExtraContent(object){
    let tmp1 =  Object.assign({}, object);
        delete tmp1.created_at
        delete tmp1.updated_at
        delete tmp1.deleted_at
        delete tmp1.image
    return tmp1
}

export function removeFromObjectIfEmpty(obj) {
    let newObj = {};
    Object.keys(obj).forEach((key) => {
        if (obj[key] && typeof obj[key] === 'object') {
            newObj[key] = removeFromObjectIfEmpty(obj[key]);
        } else if (obj[key] != null) {
            newObj[key] = obj[key];
        }
    });
    return newObj;
}